/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as service from '../../services/service';

function ViewAdventureTourRegistrationDetails() {
    const [state, setState] = useState(
        {
            remarks: ''
        });
    const [data, setData] = useState([{}]);
    const [documentData, setDocuemntData] = useState([{}]);
    const [equipmentData, setEquipmentData] = useState([{}]);
    // const [errors, setErrors] = useState({});    
    const [loading, setLoading] = useState(false);
    const userRl = JSON.parse(sessionStorage.getItem('userData')).userRole;
    const reg = JSON.parse(sessionStorage.getItem('userData')).region;

    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ADVENTURE_TOUR_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                        setState({ ...state, remarks: result.remarks });
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDocumentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ADVENTURE_TOUR_DOCUMENTS_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setDocuemntData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getEquipmentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ADVENTURE_TOUR_EQUIPMENTS_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setEquipmentData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };
    useEffect(() => {
        setLoading(true);
        getRegistrationById();
        getDocumentsById();
        getEquipmentsById();
        setLoading(false);        
    }, []);

    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const backHandler = () => {
        navigate('/adventuretourregistrations');
    };

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    const submitHandler = (regStatus) => {
        // e.preventDefault();
        const errs = {};
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Are you sure...?")) {
            fetch(
                service.UPDATE_ADVENTURE_TOUR_REGISTRATION_VERIFICATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureRegistrationId: params.id,
                        Status: userRl === 'DoT' || userRl === 'DTO' ? regStatus : '',
                        AssistantDirectorStatus: userRl === 'Assistant Director' ? regStatus : '',
                        JointDirectorStatus: userRl === 'Joint Director' ? regStatus : '',
                        DirectorStatus: userRl === 'Director' ? regStatus : '',
                        Remarks: state.remarks
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/adventuretourregistrations');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        // setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const verifyDocumentHandler = (id, docStatus) => {
        const errs = {};
        setLoading(true);
        // eslint-disable-next-line
        if (window.confirm("Are you sure...?")) {
            fetch(
                service.UPDATE_ADVENTURE_TOUR_DOCUMENT_VERIFICATION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureDocumentId: id,
                        Status: docStatus,
                        VerifiedBy: JSON.parse(window.sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        // setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const printDocument = () => {
        setTimeout(() => {
            setTimeout(() => {
                setLoading(true);
            }, 100);
            const divToPrint = document.querySelector('#divForm');
            html2canvas(divToPrint)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = 190;
                    const pageHeight = 290;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    // eslint-disable-next-line new-cap
                    const doc = new jsPDF('pt', 'mm');
                    let position = 0;
                    doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
                    heightLeft -= pageHeight;
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                        heightLeft -= pageHeight;
                    }
                    doc.save('ApplicationForm.pdf');
                    setLoading(false);
                });
        }, 1000);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div style={{ width: '100%' }}>
                <Card>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="info"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                            Adventure Tour Registration Details
                        </MDTypography>
                    </MDBox>
                    {loading ? <Oval
                        heigth="100"
                        width="100"
                        color='grey'
                        ariaLabel='loading'
                    /> :
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                                {/* <div style={{ fontSize: '12px', color: 'red' }}>{errors}</div> */}
                                <Card style={{ alignItems: 'center' }}>
                                    <MDBox pt={4} pb={3} px={3} style={{ width: '100%' }}>
                                        <div id="divForm" width="100%">
                                            <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan={2}>
                                                                <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Email Id :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.emailId}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Mobile No. :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.mobileNo}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Registration Date :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {moment(data.addedDate).format("DD/MM/YYYY")}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Applicant Name :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.applicantName}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Name of the Individual/Company/Partnership Firm/Trust etc. to be registered :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.name}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Applicant Type :
                                                                        </TableCell>
                                                                        <TableCell colSpan="5" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.applicantType}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Category For Applicant Registration :
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Adventure tour operator :
                                                                        </TableCell>
                                                                        <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.operator ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Aggregator / Resellers :
                                                                        </TableCell>
                                                                        <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.aggregator ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Camp / Resorts :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.camp ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Training Institutes and Institutes e.g. Mountaineering Institutes, Paragliding Schools etc. :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.trainingInstitute ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Association / Federation :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.association ? "Yes" : "No"}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Individual Oraganizer :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.individualOrganizer ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Govenrment Oraganizer :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.governmentOrganizer ? "Yes" : "No"}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Name and Designation of the person authorized by the organization/company :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.authorizedPerson}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Business Start Date :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {moment(data.businessStartDate).format("DD/MM/YYYY")}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Applicant Address :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.applicantAddress}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Correspondence Address if Different from Applicant Address :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.coAddress}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Region :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.region}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            District :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.district}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Whats App Number :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.whatsAppNo}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Applicant Website :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.applicantWebSite}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Applicant Social Media Handles :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.socialMediaHandles}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Adventure Activity Category :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.activityCategory}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Adventure Activities :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.activities}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Activity Location Address :
                                                                        </TableCell>
                                                                        <TableCell colSpan="5" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.activityLocationAddress}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Nauka Vihar / Jal Krida
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.naukaViharJalkrida}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Beach Name :
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.beachName}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Specify your activity if not listed above
                                                                        </TableCell>
                                                                        <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.otherActivity}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            Status :
                                                                        </TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {data.status}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    {data.activityCategory === 'Water Activities' && reg === 'Konkan' ?
                                                                        <TableRow>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Assistant Director Status
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.assistantDirectorStatus}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Joint Director Status
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.jointDirectorStatus}
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                Director Status
                                                                            </TableCell>
                                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                {data.directorStatus}
                                                                            </TableCell>
                                                                        </TableRow> : null}
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Equipments
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Equipment Name
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Units
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Manufacturer
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Purchase Date
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableBody>
                                                        {equipmentData.map((row) => (
                                                            <TableRow
                                                                key={row.equipmentId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    {row.equipement}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    {row.noOfUnits}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    {row.manufacturerName}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    {row.purchaseDate ? moment(row.purchaseDate).format("DD/MM/YYYY") : ''}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell colSpan="7" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Documents
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Document Type / Adventure Activity
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Document Name
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            File Name
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Status
                                                        </TableCell>
                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            Verification Date
                                                        </TableCell>
                                                        {(data.activityCategory === 'Water Activities' && userRl === "DoT") ||
                                                            (data.activityCategory === 'Water Activities' && reg !== 'Konkan' && userRl === "DTO") ||
                                                            (data.activityCategory !== 'Water Activities' && userRl === "DTO") ?
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Approve
                                                            </TableCell>
                                                            : null}
                                                        {(data.activityCategory === 'Water Activities' && userRl === "DoT") ||
                                                            (data.activityCategory === 'Water Activities' && reg !== 'Konkan' && userRl === "DTO") ||
                                                            (data.activityCategory !== 'Water Activities' && userRl === "DTO") ?
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Reject
                                                            </TableCell>
                                                            : null}
                                                    </TableRow>
                                                    <TableBody>
                                                        {documentData.map((row) => (
                                                            <TableRow
                                                                key={row.adventureDocumentId}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.documentType}</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    <MDButton onClick={() => handleDownload(row.fileName, row.filePath)}>{row.documentName}</MDButton>
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.fileName}</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.status}</TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.verificationDate ? moment(row.verificationDate).format("DD/MM/YYYY") : ''}</TableCell>
                                                                {(data.activityCategory === 'Water Activities' && userRl === "DoT") ||
                                                                    (data.activityCategory === 'Water Activities' && reg !== 'Konkan' && userRl === "DTO") ||
                                                                    (data.activityCategory !== 'Water Activities' && userRl === "DTO") ?
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <MDButton onClick={() => verifyDocumentHandler(row.adventureDocumentId, "Approved")}>Approved</MDButton>
                                                                    </TableCell>
                                                                    : null}
                                                                {(data.activityCategory === 'Water Activities' && userRl === "DoT") ||
                                                                    (data.activityCategory === 'Water Activities' && reg !== 'Konkan' && userRl === "DTO") ||
                                                                    (data.activityCategory !== 'Water Activities' && userRl === "DTO") ?
                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        <MDButton onClick={() => verifyDocumentHandler(row.adventureDocumentId, "Rejected")}>Rejected</MDButton>
                                                                    </TableCell>
                                                                    : null}
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                    <TableRow>
                                                        <TableCell colSpan="7" style={{ fontWeight: 'bold', fontSize: '16px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            <MDInput label="Remarks" fullWidth value={state.reamrks}
                                                                name="remarks"
                                                                onChange={changeHandler}
                                                                id="remarks"
                                                                multiline
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </MDBox>
                                    <MDBox mt={4} mb={1} textAlign="center">
                                        <Table>
                                            <TableRow>
                                                {(data.activityCategory === 'Water Activities' && userRl === "DoT") ||
                                                    (data.activityCategory === 'Water Activities' && reg === 'Konkan' && userRl === "Assistant Director") ||
                                                    (data.activityCategory === 'Water Activities' && reg === 'Konkan' && userRl === "Joint Director") ||
                                                    (data.activityCategory === 'Water Activities' && reg === 'Konkan' && userRl === "Director") ||
                                                    (data.activityCategory === 'Water Activities' && reg !== 'Konkan' && userRl === "DTO") ||
                                                    (data.activityCategory !== 'Water Activities' && userRl === "DTO") ?
                                                    <TableCell>
                                                        <MDButton color="info" onClick={() => submitHandler("Approved")}
                                                            disabled={data.documentStatus === "Rejected"
                                                                || (data.status === "Approved" && userRl === "DoT")
                                                                || (data.assistantDirectorStatus === "Approved" && userRl === "Assistant Director")
                                                                || (data.jointDirectorStatus === "Approved" && userRl === "Joint Director")
                                                                || (data.directorStatus === "Approved" && userRl === "Director")
                                                                || (data.status === "Approved" && userRl === "DTO")
                                                            }>
                                                            Approved
                                                        </MDButton>
                                                    </TableCell>
                                                    : null}
                                                {(data.activityCategory === 'Water Activities' && userRl === "DoT") ||
                                                    (data.activityCategory === 'Water Activities' && reg === 'Konkan' && userRl === "Assistant Director") ||
                                                    (data.activityCategory === 'Water Activities' && reg === 'Konkan' && userRl === "Joint Director") ||
                                                    (data.activityCategory === 'Water Activities' && reg === 'Konkan' && userRl === "Director") ||
                                                    (data.activityCategory === 'Water Activities' && reg !== 'Konkan' && userRl === "DTO") ||
                                                    (data.activityCategory !== 'Water Activities' && userRl === "DTO") ?
                                                    <TableCell>
                                                        <MDButton color="info" onClick={() => submitHandler("Rejected")}
                                                            disabled={(data.status === "Rejected" && userRl === "DoT")
                                                                || (data.assistantDirectorStatus === "Rejected" && userRl === "Assistant Director")
                                                                || (data.jointDirectorStatus === "Rejected" && userRl === "Joint Director")
                                                                || (data.directorStatus === "Rejected" && userRl === "Director")
                                                                || (data.status === "Rejected" && userRl === "DTO")
                                                            }>
                                                            Rejected
                                                        </MDButton>
                                                    </TableCell>
                                                    : null}
                                                <TableCell>
                                                    <MDButton color="info" onClick={() => printDocument()}>
                                                        PRINT
                                                    </MDButton>
                                                </TableCell>
                                                <TableCell>
                                                    <MDButton color="secondary" onClick={backHandler}>
                                                        Back
                                                    </MDButton>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </MDBox>
                                </Card>
                            </MDBox>
                        </MDBox>

                    }
                </Card>
            </div>
        </DashboardLayout>
    );
}

export default ViewAdventureTourRegistrationDetails;
